
import {
  defineComponent,
  ref,
  onMounted,
  reactive,
  getCurrentInstance,
  computed,
} from "vue";
import { useRouter } from "vue-router";
import {
  getOrderList,
  addOrder,
  updateOrder,
  getContentById,
  orderFormModel,
} from "@/api/order";
import { ElMessage } from "element-plus";
import { deepClone, getUserGroupId, toDateFilter } from "@/util/oauth";
import { Order, FileList, OrderStatusEnum } from "@/api/apiModel";
import { GroupModel } from "@/api/systemModel";
import { uploadPic } from "@/api/group";
import { useStore } from "vuex";
import { getGroupList, removeOrderApi } from "@/api/system";
import { ElMessageBox } from "element-plus/es";
import ListFilter from "../../components/list-filter.vue";

export default defineComponent({
  name: "workbench",
  components: { ListFilter },
  setup() {
    let searchform = reactive({ name: "" });

    let isShowDialog = ref(false);
    let form = ref<Order>({
      id: "",
      jsonstring: "",
      name: "",
      phone: "",
      age: "",
      height: "",
      weight: "",
      faceImgName: [],
      faceImgType: "",
      contentId: "",
      remark: "",
      orderType: "",
      orderStatus: "",
      createUserId: "",
      createtime: "",
      updateUserId: "",
      fileValid: "",
      groupId: "",
      sex: "",
      appeal: "",
    });

    const rules = ref({
      // groupId: [{ required: true, message: "请选择分组", trigger: "change" }],
      name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      phone: [{ required: true, message: "请输入电话号码", trigger: "blur" }],
      // age: [
      //   {
      //     required: true,
      //     message: "请输入年龄（1-99）",
      //     trigger: "blur",
      //   },
      // ],
      fileValid: [{ required: true, message: "请选择照片", trigger: "blur" }],
    });

    let isAdd = ref(true);
    let title = ref("");
    const addOrderDialog = () => {
      isShowDialog.value = true;
      isAdd.value = true;
      title.value = "新增订单";
      form.value.groupId = getUserGroupId();
    };

    let fileList = ref<Array<FileList>>([]);
    const updateOrderDialog = (value: Order) => {
      isShowDialog.value = true;
      fileList.value = [];
      isAdd.value = false;
      title.value = "编辑订单";
      form.value = deepClone(value);
      form.value.fileValid = form.value.faceImgName[0];
      for (const pic of form.value.faceImgName) {
        fileList.value.push({
          name: pic,
          url: `https://facemaster.meb.fit/api/face/getOssImage?fileName=${pic}`,
        });
      }
    };

    const picChange = (value: any): void => {
      form.value.fileValid = value.name;
    };

    const removeOrder = (value: any) => {
      ElMessageBox.confirm("确认删除吗?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res: any = await removeOrderApi(value.id);
        await getOrder(filterForm.value);
        if (res.code == 200) {
          ElMessage({
            type: "success",
            message: "订单已删除",
          });
        } else {
          ElMessage({
            type: "error",
            message: "订单删除失败",
          });
        }
      });
    };

    // 上传表单
    const store = useStore();

    const haveRemoveOrder = computed(() => {
      let b = false;
      store.state.user.userRole.forEach((value: any) => {
        if (!b && value.menuName == "remove_order") {
          b = true;
        }
      });
      return b;
    });

    const userName = computed(() => {
      return store.state.user.userName;
    });
    let addLoading = ref(false);
    const { proxy } = getCurrentInstance() as any;
    const addUpdateOrderApi = async (isAdd: boolean) => {
      proxy.$refs.ruleForm.validate(async (valid: any) => {
        if (valid) {
          addLoading.value = true;
          const file = proxy.$refs.upload.uploadFiles;
          form.value.faceImgName = [];
          // 图片操作
          if (file.length > 0) {
            for (let pic of file) {
              if (pic.raw != undefined) {
                let picForm = new FormData();
                picForm.append("file", pic.raw);
                let result = await uploadPic(picForm);
                form.value.faceImgName.push(result);
              } else {
                form.value.faceImgName.push(pic.name);
              }
            }
            form.value.orderType = "手工录入";
            form.value.orderStatus = OrderStatusEnum.wating;
            let res = null;
            if (isAdd) {
              form.value.createUserId = userName.value;
              res = await addOrder(form.value);
            } else {
              form.value.updateUserId = userName.value;
              const contentRes = await getContentById(form.value.id);
              res = await updateOrder(form.value, contentRes.data || "");
            }
            addLoading.value = false;
            if (res.code == 200) {
              ElMessage.success("上传信息成功");
              closeDialog();
              getOrder(filterForm.value);
            } else {
              ElMessage.error("上传信息失败：" + res.msg);
            }
          } else {
            ElMessage.error("请选择照片");
            addLoading.value = false;
          }
        } else {
          ElMessage.error("请输入信息");
        }
      });
    };
    const closeDialog = () => {
      isShowDialog.value = false;
      proxy.$refs.upload.uploadFiles = [];
      form.value = {
        id: "",
        jsonstring: "",
        name: "",
        phone: "",
        age: "",
        height: "",
        weight: "",
        faceImgName: [],
        faceImgType: "",
        contentId: "",
        remark: "",
        orderType: "",
        orderStatus: "",
        createUserId: "",
        createtime: "",
        updateUserId: "",
        fileValid: "",
      };
    };

    // 获取筛选
    let filterForm = ref<orderFormModel>({
      search: "",
      sort: "desc",
      pageSize: 20,
      pageNum: 1,
      orderStatus: "",
    });
    const getForm = (form: any) => {
      filterForm.value = form;
      getOrder(filterForm.value);
    };

    // 获取订单列表、搜索
    let pageNum = ref(1);
    let pageSize = ref(20);
    let total = ref(0);
    let orderList = ref([]);
    let tableLoading = ref(false);
    const getOrder = async (param?: orderFormModel): Promise<void> => {
      tableLoading.value = true;
      let form: orderFormModel;
      if (param != undefined) {
        form = {
          search: searchform.name,
          // orderType: OrderStatusEnum.finish,
          sort: "desc",
          pageSize: pageSize.value,
          pageNum: pageNum.value,

          updateTimeBegin: param.updateTimeBegin,
          updateTimeEnd: param.updateTimeEnd,
          formId: param.formId,
          phone: param.phone,
          orderStatus: param.orderStatus,
          ageBegin: param.ageBegin,
          ageEnd: param.ageEnd,
        };
      } else {
        form = {
          search: searchform.name,
          // orderType: OrderStatusEnum.finish,
          sort: "desc",
          pageSize: pageSize.value,
          pageNum: pageNum.value,
        };
      }

      const res = await getOrderList(form);
      tableLoading.value = false;
      if (res.code == 200) {
        orderList.value = res.data.content;
        total.value = res.data.total;
      } else {
        ElMessage.error("获取订单失败");
      }
    };
    const searchOrder = () => {
      getOrder(filterForm.value);
    };
    const handleSizeChange = (val: number) => {
      pageSize.value = val;
      getOrder(filterForm.value);
    };
    const handlePageChange = (val: number) => {
      pageNum.value = val;
      getOrder(filterForm.value);
    };

    // 开始看脸
    const url = useRouter();
    const editWorkbench = async (order: Order) => {
      // order.orderStatus = OrderStatusEnum.operating;
      // await updateOrder(order, "");
      const { href } = url.resolve({
        path: `/operateEditor`,
        query: {
          gid: "",
          gname: "",
          id: order.id,
          type: "workbench",
        },
      });
      window.open(href, "_blank");
    };
    const editAIReporty = async (order: Order) => {
      const { href } = url.resolve({
        path: `/operateEditor`,
        query: {
          gid: "",
          gname: "",
          id: order.id,
          type: "workbench",
          ai: "true",
          image: order.faceImgName[0],
        },
      });
      window.open(href, "_blank");
    };

    // 获取分组列表
    let groupList = ref<Array<GroupModel>>();
    const getGroups = async () => {
      const res = await getGroupList();
      groupList.value = res.data as Array<GroupModel>;
    };

    // 批量获取相关
    let isShowBatform = ref(false);
    let batchForm = ref({
      from: "",
      time: "",
      groupId: "",
    });
    let batFormTable = ref([]);

    onMounted(async () => {
      getGroups();

      filterForm.value.orderStatus = "待看";
      await getOrder(filterForm.value);
      store.commit("SET_ORDERTYPE", "workbench");
      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState == "visible") {
          getOrder(filterForm.value);
        }
      });
    });
    const convertDate = (time: string) => {
      return toDateFilter(time);
    };
    return {
      searchform,
      form,
      addOrderDialog,
      orderList,
      convertDate,
      editWorkbench,
      editAIReporty,
      isShowDialog,
      addUpdateOrderApi,
      rules,
      closeDialog,
      addLoading,
      updateOrderDialog,
      isAdd,
      fileList,
      title,
      searchOrder,
      picChange,
      groupList,
      batchForm,
      isShowBatform,
      batFormTable,
      removeOrder,
      haveRemoveOrder,
      tableLoading,
      pageNum,
      pageSize,
      total,
      handleSizeChange,
      handlePageChange,
      ListFilter,
      getForm,
    };
  },
});
